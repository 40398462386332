import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getWhitelistPFPNFT, setLoader} from "../../store/actions/Auth"


const WhiteList= (props) => {
    
    useEffect(()=>{
        props.getWhitelistPFPNFT()
    },[]);

    return(
        <div className='whitelist'>  
            <h1>WhiteList PFP</h1>

            <div className='table-wrap'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Wallet Address</th>
                            <th>NFT Allocations</th>
                            <th>AVAX Invested</th>
                            {/* <th>Phone</th> */}
                            {/* <th>Discord</th> */}
                            {/* <th>X</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {props.whitelistPFPNFT && props.whitelistPFPNFT.length > 0 && props.whitelistPFPNFT.map((item,index)=>
                        <tr>
                            <td>{index+1}</td>
                            <td>{item.publicAddress ? item.publicAddress  : "-"}</td>
                            <td>{item.nftAllocations ? item.nftAllocations  : "-"}</td>
                            <td>{item.avaxInvested ? item.avaxInvested  : "-"}</td>
                            {/* <td>{item.phone ? item.phone  : "-"}</td> */}
                            {/* <td>{item.discordUsername ? item.discordUsername  : "-"}</td> */}
                            {/* <td>{item.twitter ? item.twitter  : "-"}</td> */}
                        </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapDispatchToProps = {  setLoader, getWhitelistPFPNFT};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, singleNft,publicAddress, whitelistPFPNFT} = Auth;
  return { isLogin, singleNft,publicAddress, whitelistPFPNFT}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhiteList));