import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { web3 } from "../../store/web3";
import { connectMetamask, getNetworkSymbol } from "../../store/walletConnect";
import { networkType } from "../../store/config";
import logo from '../../assets/img/logo.png';

import { logout, setAddress, setNetwork, getNonce, login } from '../../store/actions/Auth';

import "./index.css";
import { Fragment } from "react";

const Login = (props) => {

  let [login, setLogin] = useState(false);

  const handleWalletChanges = (network) => {
    if (network) {
      connectMetamask(network, networkType)
        .then(async ({ address }) => {
          if (address && !login) {
            let chain = await web3.eth.getChainId();
            chain = web3.utils.hexToNumber(chain);
            chain = Number(chain);
            props.setAddress({ publicAddress: address, chain });
            props.getNonce({ publicAddress: address, chain });
            setLogin(true);
            //   return EventBus.publish("success", `wallet connect successfuly`); 
          }
        })
        .catch((error) => {
          return EventBus.publish("error", `failed to connect wallet: ${error}`);
        });
    }
  };

  const loginWallet = async (nonce) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      if (address) {
        let chain = await web3.eth.getChainId();
        chain = web3.utils.hexToNumber(chain);
        chain = Number(chain);
        const signature = await web3.eth.personal.sign(`HBurn marketplace signature ${nonce}`, address);
        if (address && signature) props.login({ address, signature, nonce, chain, history: props.history })
      }
    } catch (error) {
      return EventBus.publish("error", `failed to create signature: ${error}`);
    }
  }

  useEffect(() => {
    if (props.nonce && props.isChangeNetwork === true) loginWallet(props.nonce);
  }, [props.nonce])

  // useEffect(()=>{
  //     if (typeof window.ethereum !== 'undefined') {
  //         // Register the event listener when the component mounts
  //         window.ethereum.on('chainChanged',()=>{
  //             setLogin(false)
  //             props.logout();
  //         });
  //         // Listen for accounts changed event
  //         window.ethereum.on('accountsChanged',()=>{
  //             setLogin(false)
  //             props.logout();
  //         });
  //     }
  // },[]);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      if (props.isChangeNetwork === true) {
        handleWalletChanges(props.network);
        setLogin(false);
      }
    }
  }, [props.isChangeNetwork]);

  return (
    <div className="login-page">
      <div className="login-area mx-auto">
        <div className="login-form">
          <h4 className="login-title d-block">
            <img src={logo} alt="logo" className="logoLogin" />
          </h4>
          <h1>Welcome TO Super Admin</h1>
          <p className="text-white">
            Access to manage the HBurn App
          </p>
          {/* {netId != networkId ? (
              <div className="login-text">
                <p>{message}</p>
              </div>
            ) : ( */}
          {/* <div className='form-group'>
                <select onChange={e=>props.setNetwork(e.target.value)} value={props.network}>
                  <option value={"bnb"}>BNB</option>
                  <option value={"eth"}>ETH</option>
                  <option value={"avax"}>AVAX</option>
                  <option value={"xdc"}>XDC</option>
                </select>
              </div> */}

          <Fragment>
            <ValidatorForm
              className="validator-form"
              onSubmit={() => handleWalletChanges(props.network)}
            >
              <Button
                type="Submit"
                variant="contained"
                className="common-btn"
              // className="common-btn"
              >
                Login with Wallet
                {/* <img src={XDC} alt="xdc" className='xdc' /> */}
              </Button>
            </ValidatorForm>
          </Fragment>
          {/* )} */}
        </div>
      </div>
      <div className='bgLoginPg'></div>
    </div>
  );
}

const mapDispatchToProps = { logout, setAddress, setNetwork, getNonce, login };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork } = Auth;
  return { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
