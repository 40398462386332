import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPatnerNFT, setLoader } from "../../store/actions/Auth"


const PresaleNFTHolders = (props) => {

    useEffect(() => {
        props.getPatnerNFT()
    }, []);

    return (
        <div className='whitelist'>
            <h1>WhiteList PFP</h1>

            <div className='table-wrap'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Wallet Address</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            {/* <th>Discord</th> */}
                            {/* <th>X</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {props.partnerNFT && props.partnerNFT.length > 0 && props.partnerNFT.map((item, index) =>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.publicAddress ? item.publicAddress : "-"}</td>
                                <td>{item.name ? item.name : "-"}</td>
                                <td>{item.email ? item.email : "-"}</td>
                                <td>{item.phone ? item.phone  : "-"}</td>
                                {/* <td>{item.discordUsername ? item.discordUsername  : "-"}</td> */}
                                {/* <td>{item.twitter ? item.twitter  : "-"}</td> */}
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapDispatchToProps = { setLoader, getPatnerNFT };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, singleNft, publicAddress, partnerNFT } = Auth;
    return { isLogin, singleNft, publicAddress, partnerNFT }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PresaleNFTHolders));