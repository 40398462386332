import Web3 from 'web3';

export const connectMetamask = async (network, isTestnet) => {
    try {
      // Check if Metamask is installed
      if (typeof window.ethereum !== 'undefined') {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();

        let x = await web3.currentProvider.chainId;
        if (!x) {
            return Promise.reject(new Error({message:`Please Install Metamask!!!`}));
        }

        console.log("**************1");

        // Define network details
        let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
        switch (network) {
          case 'avalanche':
            rpcUrl = isTestnet
              ? ['https://api.avax-test.network/ext/bc/c/rpc']
              : ['https://api.avax.network/ext/bc/C/rpc']
            chainId = isTestnet ? '0xa869' : '0xa86a';
            chainName = isTestnet ? 'Avalanche Fuji Testnet' : 'Avalanche C-Chain';
            nativeCurrency = {
              name:  isTestnet ? 'Avalanche Fuji Testnet' : 'Avalanche C-Chain',
              symbol: isTestnet ? 'AVAX' : 'AVAX',
              decimals: 18,
            };
            blockExplorerUrl = isTestnet
              ? 'https://testnet.snowtrace.io/'
              : 'https://snowtrace.io/';
            break;
          default:
            return Promise.reject(new Error({message:'Invalid network'}));
        }
            const netId = await web3.eth.net.getId();
            if (isTestnet && netId !== 43113) {
                await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrl,
                    blockExplorerUrls: [blockExplorerUrl],
                    },
                ],
                });
            }else if(!isTestnet && netId !== 43114){
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrl,
                    blockExplorerUrls: [blockExplorerUrl],
                    },
                ],
                });
            }
            else {
                // Switch to the selected network
                // await web3.eth.switchNetwork(chainId);
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId }],
                });
            }

        console.log("**************2");
        
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];
        return Promise.resolve({address});
        
      } else {
        console.log("**************3");
        console.log('Metamask is not installed');
        return Promise.reject(new Error({message:'Metamask is not installed'}));
      }
    } catch (error) {
      console.log("**************4");
      console.log('Error connecting to Metamask:', error);
      return Promise.reject(error.message);
    }
};

export const getNetworkSymbol = (networkId) => {
    const networkMap = {
      '43114': 'avalanche', // Avalanche Mainnet
      '43113': 'avalanche', // Avalanche Testnet
    };
  
    return networkMap[networkId] || 'Unknown';
};